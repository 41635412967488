import { useState } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import Tooltip from '../components/shared/tooltip/Tooltip';
import LogOutIcon from './shared/icons/LogOutIcon';
import EyeIcon from './shared/icons/EyeIcon';
import SettingsIcon from './shared/icons/SettingsIcon';
import { HiChevronDown } from 'react-icons/hi2';

const UserProfileDropdown = ({
  profileDropdownRef,
  setIsProfileOpen,
  isProfileOpen,
  login,
  setLogin,
  userRole,
  viewAsDropdownRef,
  isViewAsOpen,
  clientsQueries,
  setSelectedOption,
  handleQueryChange,
  setIsViewAsOpen,
  selectedOption,
  selectedClientQuery,
  setSelectedRows,
  regionList,
}) => {
  const navigate = useNavigate();
  const [showClients, setShowClients] = useState(
    Array(regionList.length).fill(true)
  );

  const toggleRegion = (index) => {
    const newShowClients = [...showClients];
    newShowClients[index] = !newShowClients[index];
    setShowClients(newShowClients);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className='dd_profile' ref={profileDropdownRef}>
        <div
          className='dd-setup_profile'
          onClick={() => setIsProfileOpen(!isProfileOpen)}
        >
          <div className='user-avatar_topnav'>
            <span>
              {login?.firstName && login?.lastName
                ? login?.firstName.charAt(0).toUpperCase() +
                  login?.lastName.charAt(0).toUpperCase()
                : login?.username?.charAt(0).toUpperCase()}
            </span>
          </div>
        </div>
        {isProfileOpen && (
          <div
            className='dd-menu_profile overflow-style'
            style={{
              zIndex: 999,
              width: 'max-content',
              padding: '1rem 1.75rem',
            }}
          >
            <span
              style={{
                textTransform: 'uppercase',
                fontWeight: '700',
                fontSize: '10px',
                lineHeight: '15px',
                color: '#BDBDBD',
                letterSpacing: '0.06em',
              }}
            >
              Account
            </span>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '.5rem',
                marginTop: '.5rem',
              }}
            >
              <div className='user-avatar_topnav-sm'>
                <span style={{ fontSize: '1rem' }}>
                  {login?.firstName && login?.lastName
                    ? login?.firstName.charAt(0).toUpperCase() +
                      login?.lastName.charAt(0).toUpperCase()
                    : login?.username?.charAt(0).toUpperCase()}
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {' '}
                <strong style={{ fontSize: '16px' }}>
                  {login.firstName} {login.lastName}
                </strong>
                {userRole.map((role) => {
                  return (
                    <span style={{ fontSize: '14px' }} key={role}>
                      {role.slice(role.lastIndexOf('.') + 1)}
                    </span>
                  );
                })}
              </div>
            </div>

            <hr className='mx-1' />

            <span
              style={{
                textTransform: 'uppercase',
                fontWeight: '700',
                fontSize: '10px',
                lineHeight: '15px',
                color: '#BDBDBD',
                letterSpacing: '0.06em',
              }}
            >
              Settings and support
            </span>

            <div
              onClick={() => {
                navigate(`/profile/${login.firstName + login.lastName}`);
                setIsProfileOpen(false);
              }}
              className='dd-item_profile'
            >
              <SettingsIcon stroke='#BDBDBD' />
              <span style={{ color: '#222222' }}>Settings</span>{' '}
            </div>

            {/* CLIENT VIEW DROPDOWN */}
            <Tooltip
              content='Switch to a different client view'
              placement='bottom'
            >
              <div style={{ display: 'flex', marginTop: '.5rem' }}>
                <div
                  className='dd'
                  onSelect={(e) => handleQueryChange(e)}
                  ref={viewAsDropdownRef}
                >
                  <div
                    className='dd-toggle px-0 border-0'
                    onClick={() => setIsViewAsOpen(!isViewAsOpen)}
                  >
                    <div className='d-flex align-items-center gap-2'>
                      {' '}
                      <EyeIcon stroke='#BDBDBD' />
                      <span
                        className='dd-label'
                        style={{
                          fontWeight: 'normal',
                        }}
                      >
                        {' '}
                        {selectedClientQuery.replaceAll('_', ' ')}
                      </span>
                    </div>

                    <span>
                      <BiChevronRight color='#BDBDBD' size='24' />
                    </span>
                  </div>
                  {isViewAsOpen && (
                    <div
                      className='dd-menu w-100 overflow-style pb-0'
                      style={{ position: 'absolute' }}
                    >
                      {clientsQueries
                        .filter(
                          (clientQuery) => clientQuery.name === 'Account.Global'
                        )
                        .map((clientQuery) => {
                          const queryKey = clientQuery.name.slice(
                            clientQuery.name.indexOf('.') + 1
                          );
                          const isSelected = queryKey === selectedOption;
                          return (
                            <div
                              className='dd-item'
                              key={clientQuery.name}
                              onClick={() => {
                                setSelectedOption(queryKey);
                                handleQueryChange(queryKey);
                                setSelectedRows([]);
                                setIsProfileOpen(false);
                                setIsViewAsOpen(false);
                              }}
                              style={{
                                backgroundColor: isSelected
                                  ? 'var(--intuita-main)'
                                  : 'white',
                                color: isSelected ? 'white' : 'black',
                              }}
                            >
                              {clientQuery.name.slice(
                                clientQuery.name.indexOf('.') + 1
                              )}
                            </div>
                          );
                        })}
                      <hr className='my-2' />
                      {regionList.map((region, index) => {
                        const isRegionVisible = showClients[index];

                        if (
                          clientsQueries.filter(
                            (clientQuery) =>
                              clientQuery.name !== 'Account.Global' &&
                              clientQuery.region === region
                          ).length !== 0
                        ) {
                          return (
                            <div key={index} className='mb-1'>
                              {/* TOOGLE REGION START */}
                              <div
                                className='dd px-2'
                                onClick={() => toggleRegion(index)}
                              >
                                <div className='dd-toggle'>
                                  <span
                                    className='dd-label'
                                    style={{
                                      fontWeight: '600',
                                      fontSize: '1rem',
                                    }}
                                  >
                                    {region || 'N/A'}
                                  </span>

                                  <span>
                                    <HiChevronDown color='#BDBDBD' size='20' />
                                  </span>
                                </div>
                              </div>
                              {/* TOOGLE REGION END */}

                              {/*   <h4
                                onClick={() => toggleRegion(index)}
                                style={{
                                  backgroundColor: 'whitesmoke',
                                  marginBottom: 0,
                                  fontWeight: 700,
                                  paddingTop: '0.25rem',
                                  paddingBottom: '0.25rem',
                                  cursor: 'pointer',
                                }}
                              >
                                {region || 'N/A'}
                              </h4> */}

                              {isRegionVisible && (
                                <>
                                  {clientsQueries
                                    .filter(
                                      (clientQuery) =>
                                        clientQuery.name !== 'Account.Global' &&
                                        clientQuery.region === region
                                    )
                                    .sort((a, b) => {
                                      const keyA = a.name.slice(
                                        a.name.indexOf('.') + 1
                                      );
                                      const keyB = b.name.slice(
                                        b.name.indexOf('.') + 1
                                      );
                                      return keyA.localeCompare(keyB);
                                    })
                                    .map((clientQuery) => {
                                      const queryKey = clientQuery.name.slice(
                                        clientQuery.name.indexOf('.') + 1
                                      );
                                      const isSelected =
                                        queryKey === selectedOption;
                                      if (
                                        clientQuery.name.startsWith('Account.')
                                      ) {
                                        return (
                                          <div
                                            className='dd-item'
                                            key={clientQuery.name}
                                            onClick={() => {
                                              setSelectedOption(queryKey);
                                              handleQueryChange(queryKey);
                                              setSelectedRows([]);
                                              setIsProfileOpen(false);
                                              setIsViewAsOpen(false);
                                            }}
                                            style={{
                                              backgroundColor: isSelected
                                                ? 'var(--intuita-main)'
                                                : 'white',
                                              color: isSelected
                                                ? 'white'
                                                : 'black',
                                              marginTop: '0.5rem',
                                              marginBottom: '0.5rem',
                                            }}
                                          >
                                            {clientQuery.name
                                              .slice(
                                                clientQuery.name.indexOf('.') +
                                                  1
                                              )
                                              .replace(/_/gi, ' ')}{' '}
                                          </div>
                                        );
                                      }
                                    })}
                                </>
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            </Tooltip>

            <hr className='mx-1' />

            <div
              onClick={() => {
                signOut();
                setLogin({
                  formType: 'signUp',
                });
                handleQueryChange('');
                sessionStorage.clear();
              }}
              className='dd-setup-item cursor-pointer'
            >
              {' '}
              <div className='d-flex gap-3 align-items-center'>
                <LogOutIcon stroke='red' />
                <span style={{ color: 'red' }}>Logout</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfileDropdown;
