import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import { AuthProvider } from './context/AuthProvider.jsx';
import QueryProvider from './dashboard/context/QueryProvider';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'graphql/apollo-graphql/apolloClient';
import { LicenseManager } from 'ag-grid-enterprise';
import './index.css';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-049202 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Data DQ Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Data DQ )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Data DQ )_need_to_be_licensed___( Data DQ )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 15 October 2024 )____[v2]_MTcyODk0NjgwMDAwMA==52afaffc6e9dfc9dea1546e442c1f76f'
);

Amplify.configure(amplifyconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <Provider store={store}>
        <QueryProvider>
          <ApolloProvider client={apolloClient}>
            <App />
          </ApolloProvider>
        </QueryProvider>
      </Provider>
    </AuthProvider>
  </BrowserRouter>
);

reportWebVitals();
