import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import genmediaLogo from '../assets/img/logos/genmediaNewLogo.png';
import SettingsIcon from './shared/icons/SettingsIcon';
import useAuth from '../hooks/useAuth.js';
import ChevronDownIcon from './shared/icons/ChevronDown';
import { useLocation } from 'react-router-dom';
import useOutsideClick from '../utils/useOutsideClick';
import UserProfileDropdown from './UserProfileDropdown';
import { listUserGroups } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import './styles/topnav.css';

const Topnav = ({
  addNewCampaign,
  setAddNewCampaign,
  selectedClientQuery,
  setSelectedClientQuery,
  showImportCsvModal,
  setShowImportCsvModal,
  setSelectedRows,
  navData,
}) => {
  const client = generateClient();

  const { login, setLogin } = useAuth();
  const location = useLocation();

  const [userRole, setUserRole] = useState([]);
  const [clientsQueries, setClientsQueries] = useState([]);
  const viewAsDropdownRef = useRef(null);
  const setupDropdownRef = useRef(null);
  const settingsDropdownRef = useRef(null);
  const qualityDropdownRef = useRef(null);
  const reportingDropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);

  function handleQueryChange(e) {
    let clientQuery = e;
    setSelectedClientQuery(clientQuery);
    sessionStorage.setItem('ClientQuery', clientQuery);
  }

  const [isViewAsOpen, setIsViewAsOpen] = useState(false);
  const [isSetupDropdownOpen, setIsSetupDropdownOpen] = useState(false);
  const [isReportingDropdownOpen, setIsReportingDropdownOpen] = useState(false);
  const [isQualityDropdownOpen, setIsQualityDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [regionList, setRegionList] = useState([]);

  useEffect(() => {
    fetchRegions();
  }, [login.permissions]);

  useOutsideClick(setupDropdownRef, () => setIsSetupDropdownOpen(false));
  useOutsideClick(qualityDropdownRef, () => setIsQualityDropdownOpen(false));
  useOutsideClick(viewAsDropdownRef, () => setIsViewAsOpen(false));
  useOutsideClick(settingsDropdownRef, () => setIsSettingsOpen(false));
  useOutsideClick(reportingDropdownRef, () =>
    setIsReportingDropdownOpen(false)
  );
  useOutsideClick(profileDropdownRef, () => setIsProfileOpen(false));

  const fetchRegions = async () => {
    try {
      let curUserGroups = login.permissions.filter((item) =>
        item.startsWith('Account.')
      );
      let nextTokenApi;
      const results = [];

      do {
        let response = await client.graphql({
          query: listUserGroups,
          variables: { input: { nextToken: nextTokenApi } },
        });
        const json = response.data.listUserGroups;
        const { nextToken, items } = json;
        nextTokenApi = nextToken;
        results.push(...items);
      } while (nextTokenApi);
      let uniqueRegions = [...new Set(results.map((item) => item['region']))];

      let filteredResults = results.filter((result) =>
        curUserGroups.includes(result.name)
      );
      setClientsQueries(filteredResults);
      setRegionList(uniqueRegions);
      setUserRole(login.permissions.filter((item) => item.startsWith('Role.')));
    } catch (error) {
      console.error(error);
      setRegionList([]);
      setClientsQueries([]);
      setUserRole([]);
    }
  };

  useEffect(() => {
    setSelectedOption(selectedClientQuery);
  }, []);

  return (
    <div className='topnav-wrapper'>
      <div className='topnav-left'>
        {/* LOGO */}
        <div>
          <img
            src={genmediaLogo}
            style={{ height: '32px', width: '32px' }}
            alt='data dq logo'
          />
        </div>

        {/* NAV LINKS */}
        <nav className='topnav-navigation'>
          {/* DATA QUALITY */}

          {navData.dataQuality.readPermissions.includes(
            login.permissions.find((perm) => {
              return perm.startsWith('Role.');
            })
          ) && (
            <div style={{ display: 'flex' }}>
              <div className='dd' ref={qualityDropdownRef}>
                <div
                  className='dd-setup_toggle'
                  onClick={() => {
                    isSetupDropdownOpen && setIsSetupDropdownOpen(false);
                    isReportingDropdownOpen &&
                      setIsReportingDropdownOpen(false);
                    isProfileOpen && setIsProfileOpen(false);
                    isViewAsOpen && setIsViewAsOpen(false);
                    setIsQualityDropdownOpen(!isQualityDropdownOpen);
                  }}
                >
                  <div
                    className='h-100'
                    style={{
                      background: isQualityDropdownOpen ? '#232751' : null,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                      padding: '.5rem',
                    }}
                  >
                    <span
                      className='dd-setup_label'
                      style={{
                        color: 'white',
                        fontWeight: isQualityDropdownOpen ? 'bold' : null,
                      }}
                    >
                      Quality
                    </span>

                    <span>
                      <ChevronDownIcon stroke='#BDBDBD' />
                    </span>
                  </div>
                </div>
                {isQualityDropdownOpen && (
                  <div className='dd-setup_menu overflow-style'>
                    {navData.dataHealth.readPermissions.includes(
                      login.permissions.find((perm) => {
                        return perm.startsWith('Role.');
                      })
                    ) && (
                      <Link
                        onClick={() => {
                          if (isQualityDropdownOpen === true) {
                            setIsQualityDropdownOpen(false);
                          }
                        }}
                        className='dd-setup_item'
                        to='/data-health'
                      >
                        Data Health
                      </Link>
                    )}
                    {navData.dataCompliance.readPermissions.includes(
                      login.permissions.find((perm) => {
                        return perm.startsWith('Role.');
                      })
                    ) && (
                      <Link
                        onClick={() => {
                          if (isQualityDropdownOpen === true) {
                            setIsQualityDropdownOpen(false);
                          }
                        }}
                        className='dd-setup_item'
                        to='/data-compliance'
                      >
                        Data Compliance
                      </Link>
                    )}
                    {navData.dataExplore.readPermissions.includes(
                      login.permissions.find((perm) => {
                        return perm.startsWith('Role.');
                      })
                    ) && (
                      <Link
                        onClick={() => {
                          if (isQualityDropdownOpen === true) {
                            setIsQualityDropdownOpen(false);
                          }
                        }}
                        className='dd-setup_item'
                        to='/data-explore'
                      >
                        Data Explore
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {navData.mappings.readPermissions.includes(
            login.permissions.find((perm) => {
              return perm.startsWith('Role.');
            })
          ) && (
            <Link
              className='topnav-navigation_item h-100'
              style={{
                borderBottom:
                  location.pathname.startsWith('/mappings') &&
                  '2px solid var(--intuita-main)',
                fontWeight: location.pathname.startsWith('/mappings') && 'bold',
              }}
              onClick={() => {
                if (addNewCampaign) setAddNewCampaign(false);
                if (showImportCsvModal) setShowImportCsvModal(false);
              }}
              to='/mappings'
            >
              Mappings
            </Link>
          )}
          {navData?.taxonomy?.readPermissions.includes(
            login.permissions.find((perm) => {
              return perm.startsWith('Role.');
            })
          ) && (
            <Link
              className='topnav-navigation_item'
              style={{
                borderBottom:
                  location.pathname.startsWith('/taxonomies') &&
                  '2px solid var(--intuita-main)',
                fontWeight:
                  location.pathname.startsWith('/taxonomies') && 'bold',
              }}
              onClick={() => {
                if (showImportCsvModal) setShowImportCsvModal(false);
              }}
              to='/taxonomies'
            >
              Taxonomies
            </Link>
          )}
          {navData?.lists?.readPermissions.includes(
            login.permissions.find((perm) => {
              return perm.startsWith('Role.');
            })
          ) && (
            <Link
              className='topnav-navigation_item'
              style={{
                borderBottom:
                  location.pathname.startsWith('/lists') &&
                  '2px solid var(--intuita-main)',
                fontWeight: location.pathname.startsWith('/lists') && 'bold',
              }}
              onClick={() => {
                if (showImportCsvModal) setShowImportCsvModal(false);
              }}
              to='/lists'
            >
              Lists
            </Link>
          )}

          {(navData.reports.readPermissions.includes(
            login.permissions.find((perm) => {
              return perm.startsWith('Role.');
            })
          ) ||
            navData.dashboard.readPermissions.includes(
              login.permissions.find((perm) => {
                return perm.startsWith('Role.');
              })
            ) ||
            navData.performance.readPermissions.includes(
              login.permissions.find((perm) => {
                return perm.startsWith('Role.');
              })
            )) && (
            <div style={{ display: 'flex' }}>
              <div className='dd' ref={reportingDropdownRef}>
                <div
                  className='dd-setup_toggle'
                  onClick={() => {
                    isSetupDropdownOpen && setIsSetupDropdownOpen(false);
                    isQualityDropdownOpen && setIsQualityDropdownOpen(false);
                    isProfileOpen && setIsProfileOpen(false);
                    isViewAsOpen && setIsViewAsOpen(false);
                    setIsReportingDropdownOpen(!isReportingDropdownOpen);
                  }}
                >
                  <div
                    className='h-100'
                    style={{
                      background: isReportingDropdownOpen ? '#232751' : null,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                      padding: '.5rem',
                    }}
                  >
                    <span
                      className='dd-setup_label'
                      style={{
                        color: 'white',
                        fontWeight: isReportingDropdownOpen ? 'bold' : null,
                      }}
                    >
                      Reporting
                    </span>

                    <span>
                      {isReportingDropdownOpen ? (
                        <ChevronDownIcon stroke='#BDBDBD' />
                      ) : (
                        <ChevronDownIcon stroke='#BDBDBD' />
                      )}
                    </span>
                  </div>
                </div>
                {isReportingDropdownOpen && (
                  <div className='dd-setup_menu overflow-style'>
                    {navData.reports.readPermissions.includes(
                      login.permissions.find((perm) => {
                        return perm.startsWith('Role.');
                      })
                    ) && (
                      <Link
                        onClick={() => {
                          if (isReportingDropdownOpen === true) {
                            setIsReportingDropdownOpen(false);
                          }
                        }}
                        className='dd-setup_item'
                        to='/performance-report'
                      >
                        Performance Report
                      </Link>
                    )}

                    {navData.performance.readPermissions.includes(
                      login.permissions.find((perm) => {
                        return perm.startsWith('Role.');
                      })
                    ) && (
                      <Link
                        onClick={() => {
                          if (isReportingDropdownOpen === true) {
                            setIsReportingDropdownOpen(false);
                          }
                        }}
                        className='dd-setup_item'
                        to='/campaign-performance'
                      >
                        Campaign Performance
                      </Link>
                    )}
                    {navData.dashboard.readPermissions.includes(
                      login.permissions.find((perm) => {
                        return perm.startsWith('Role.');
                      })
                    ) && (
                      <Link
                        onClick={() => {
                          if (isReportingDropdownOpen === true) {
                            setIsReportingDropdownOpen(false);
                          }
                        }}
                        className='dd-setup_item'
                        to='/dashboards'
                      >
                        Dashboards
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {(navData.clientManagement.readPermissions.includes(
            login.permissions.find((perm) => {
              return perm.startsWith('Role.');
            })
          ) ||
            navData?.tableDefinitions?.readPermissions.includes(
              login.permissions.find((perm) => {
                return perm.startsWith('Role.');
              })
            )) && (
            <div className='dd' ref={setupDropdownRef}>
              <div
                className='dd-setup_toggle'
                onClick={() => {
                  if (isProfileOpen) setIsProfileOpen(false);
                  if (isViewAsOpen) setIsViewAsOpen(false);
                  setIsSetupDropdownOpen(!isSetupDropdownOpen);
                }}
              >
                <div
                  style={{
                    background: isSetupDropdownOpen ? '#232751' : null,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    padding: '.5rem',
                    height: '100%',
                  }}
                >
                  <span
                    className='dd-setup_label'
                    style={{
                      color: 'white',
                      fontWeight: isSetupDropdownOpen ? 'bold' : null,
                    }}
                  >
                    Setup
                  </span>

                  <span>
                    <ChevronDownIcon stroke='#BDBDBD' />
                  </span>
                </div>
              </div>
              {isSetupDropdownOpen && (
                <div className='dd-setup_menu overflow-style'>
                  {navData?.clientManagement?.readPermissions.includes(
                    login.permissions.find((perm) => {
                      return perm.startsWith('Role.');
                    })
                  ) && (
                    <Link
                      onClick={() => {
                        if (showImportCsvModal) setShowImportCsvModal(false);
                        if (isSetupDropdownOpen === true) {
                          setIsSetupDropdownOpen(false);
                        }
                      }}
                      className='dd-setup_item'
                      to='/client-management'
                    >
                      Client Management
                    </Link>
                  )}
                  {navData?.tableDefinitions?.readPermissions.includes(
                    login.permissions.find((perm) => {
                      return perm.startsWith('Role.');
                    })
                  ) && (
                    <Link
                      onClick={() => {
                        if (showImportCsvModal) setShowImportCsvModal(false);
                        if (isSetupDropdownOpen === true) {
                          setIsSetupDropdownOpen(false);
                        }
                      }}
                      className='dd-setup_item'
                      to='/table-definitions'
                    >
                      Mapping Setup
                    </Link>
                  )}
                </div>
              )}
            </div>
          )}
        </nav>
      </div>

      <div className='topnav-right'>
        {/* ACTION ICONS */}
        <div style={{ display: 'flex' }}>
          <div className='dd' ref={settingsDropdownRef}>
            <div
              className='dd-setup_toggle'
              onClick={() => {
                if (isProfileOpen) setIsProfileOpen(false);
                if (isViewAsOpen) setIsViewAsOpen(false);
                setIsSettingsOpen(!isSettingsOpen);
              }}
            >
              <div
                className='h-100'
                style={{
                  background: isSettingsOpen ? '#232751' : null,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  padding: '.5rem',
                }}
              >
                <span
                  className='dd-setup_label'
                  style={{
                    color: 'white',
                    fontWeight: isSettingsOpen ? 'bold' : null,
                  }}
                >
                  <SettingsIcon />
                </span>
              </div>
            </div>
            {isSettingsOpen && (
              <div
                className='dd-setup_menu overflow-style'
                style={{ zIndex: 999 }}
              >
                {navData?.clientManagement?.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (showImportCsvModal) setShowImportCsvModal(false);
                      if (isSettingsOpen === true) {
                        setIsSettingsOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/admin/user-management'
                  >
                    Manage Users
                  </Link>
                )}
                {navData?.account?.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (showImportCsvModal) setShowImportCsvModal(false);
                      if (isSettingsOpen === true) {
                        setIsSettingsOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to={`/profile/${login.firstName + login.lastName}`}
                  >
                    User Profile
                  </Link>
                )}
                {navData?.audit?.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (showImportCsvModal) setShowImportCsvModal(false);
                      if (isSettingsOpen === true) {
                        setIsSettingsOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/audit'
                  >
                    History Log
                  </Link>
                )}

                {navData?.support?.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (showImportCsvModal) setShowImportCsvModal(false);
                      if (isSettingsOpen === true) {
                        setIsSettingsOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/support-center'
                  >
                    Support
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>

        <div className='topnav-vertical_divider' />

        {/* USER PROFILE */}

        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <UserProfileDropdown
            profileDropdownRef={profileDropdownRef}
            setIsProfileOpen={setIsProfileOpen}
            isProfileOpen={isProfileOpen}
            login={login}
            setLogin={setLogin}
            userRole={userRole}
            viewAsDropdownRef={viewAsDropdownRef}
            isViewAsOpen={isViewAsOpen}
            clientsQueries={clientsQueries}
            setSelectedOption={setSelectedOption}
            handleQueryChange={handleQueryChange}
            setIsViewAsOpen={setIsViewAsOpen}
            selectedOption={selectedOption}
            selectedClientQuery={selectedClientQuery}
            setSelectedRows={setSelectedRows}
            regionList={regionList}
          />
          <div>
            <h4
              style={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '500',
                margin: '0',
                color: 'white',
              }}
            >
              <div
                style={{
                  fontSize: '1rem',
                }}
              >
                {selectedClientQuery.replaceAll('_', ' ')}
              </div>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topnav;
