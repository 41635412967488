import { gql } from '@apollo/client';

// remove later
export const GET_S2_MAPPINGS_CREATIVE = gql`
  query GetMappings {
    s2_mapping(
      orderBy: { source_system: asc, customer_name: asc, campaign_name: asc }
      where: {
        _neq: { campaign_name: "", verified: "No" }
        _eq: { mapping_type: "Creative" }
      }
    ) {
      mapping_id
      Alias: user_column_50
      customer_name
      Creative_ID: campaign_name
      Creative_String: user_column_13
      Creative_Concept: user_column_1
      Image: user_column_2
      Copy_Headline: user_column_3
      CTA: user_column_4
      Ad_Language: user_column_5
      unit_type: user_column_6
      Long_Description: user_column_7
      Orientation: user_column_8
      Creative_Unit_Size: user_column_9
      Audio: user_column_10
      Subtitles: user_column_11
      Landing_Page: user_column_12
      verified
      compliant
      created_by
      created_ts
      last_updated_by
      last_updated_ts
    }
  }
`;

// remove later
export const GET_S2_UNVERIFIED_MAPPINGS_CREATIVE = gql`
  query GetUnverifiedMappings {
    s2_mapping(
      orderBy: { source_system: asc, customer_name: asc, campaign_name: asc }
      where: {
        _neq: { campaign_name: "" }
        _eq: { verified: "No", mapping_type: "Creative" }
      }
    ) {
      mapping_id
      Alias: user_column_50
      customer_name
      Creative_ID: campaign_name
      Creative_String: user_column_13
      Creative_Concept: user_column_1
      Image: user_column_2
      Copy_Headline: user_column_3
      CTA: user_column_4
      Ad_Language: user_column_5
      unit_type: user_column_6
      Long_Description: user_column_7
      Orientation: user_column_8
      Creative_Unit_Size: user_column_9
      Audio: user_column_10
      Subtitles: user_column_11
      Landing_Page: user_column_12
      verified
      compliant
      created_by
      created_ts
      last_updated_by
      last_updated_ts
    }
  }
`;

//
//
//
//
// LISTS
//
//
//
//
//
export const GET_S2_LIST_NAMES_STRING = 'GET_S2_LIST_NAMES';
export const GET_S2_LIST_GLOBAL_NAMES_STRING = 'GET_S2_LIST_GLOBAL_NAMES';
export const GET_S2_LIST_CLIENT_NAMES_STRING = 'GET_S2_LIST_CLIENT_NAMES';
export const GET_S2_LIST_VALUES_STRING = 'GET_S2_LIST_VALUES';
export const GET_S2_LIST_GLOBAL_VALUES_STRING = 'GET_S2_LIST_GLOBAL_VALUES';
export const GET_S2_LIST_CLIENT_VALUES_STRING = 'GET_S2_LIST_CLIENT_VALUES';
export const GET_S2_OTHER_LIST_NAMES_STRING = 'GET_S2_OTHER_LIST_NAMES';
export const GET_S2_OTHER_GLOBAL_LIST_NAMES_STRING =
  'GET_S2_OTHER_GLOBAL_LIST_NAMES';
export const GET_S2_OTHER_CLIENT_LIST_NAMES_STRING =
  'GET_S2_OTHER_CLIENT_LIST_NAMES';
export const GET_S2_LIST_DATA_BY_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_BY_LIST_NAME';
export const GET_S2_LIST_DATA_GLOBAL_BY_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_GLOBAL_BY_LIST_NAME';
export const GET_S2_LIST_DATA_CLIENT_BY_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_CLIENT_BY_LIST_NAME';
export const GET_S2_LIST_DATA_WITHOUT_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_WITHOUT_LIST_NAME';
export const GET_S2_LIST_DATA_GLOBAL_WITHOUT_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_GLOBAL_WITHOUT_LIST_NAME';
export const GET_S2_LIST_DATA_CLIENT_WITHOUT_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_CLIENT_WITHOUT_LIST_NAME';
//list_order_sequence
export const GET_S2_GLOBAL_LIST_VALUES = gql`
  query GetListValues($mapping_type: String = "") {
    s2_lists(
      where: { _eq: { mapping_type: $mapping_type, client_name: "Global" } }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_S2_LIST_DATA_BY_LIST_NAME_AND_CLIENT_NAME = gql`
  query GetListValuesByName(
    $list_name: String = ""
    $client_name: String = ""
    $mapping_type: String = ""
  ) {
    s2_lists(
      where: {
        _eq: {
          list_name: $list_name
          mapping_type: $mapping_type
          client_name: $client_name
        }
      }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_S2_LIST_DATA_BY_LIST_NAME = gql`
  query GetListValuesByName(
    $list_name: String = ""
    $mapping_type: String = ""
  ) {
    s2_lists(
      where: { _eq: { list_name: $list_name, mapping_type: $mapping_type } }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_S2_GLOBAL_LIST_DATA_BY_LIST_NAME = gql`
  query GetListValuesByName(
    $list_name: String = ""
    $mapping_type: String = ""
  ) {
    s2_lists(
      where: {
        _eq: {
          list_name: $list_name
          mapping_type: $mapping_type
          client_name: "Global"
        }
      }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_S2_LIST_VALUES = gql`
  query GetListValues($mapping_type: String = "") {
    s2_lists(
      where: { _eq: { mapping_type: $mapping_type } }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

//
//
//
//
// TAXONOMIES
//
//
//
//
//
export const GET_S2_TAXONOMIES_STRING = 'GET_S2_TAXONOMIES';

export const GET_S2_TAXONOMY_INDEX_HIGHEST_STRING =
  'GET_S2_TAXONOMY_INDEX_HIGHEST';

export const GET_S2_TAXONOMY_BY_PREFIX_STRING = 'GET_S2_TAXONOMY_BY_PREFIX';
export const GET_S2_GLOBAL_TAXONOMIES_STRING = 'GET_S2_GLOBAL_TAXONOMIES';
export const GET_S2_CLIENT_TAXONOMIES_STRING = 'GET_S2_CLIENT_TAXONOMIES';

export const GET_S2_GLOBAL_TAXONOMIES = gql`
  query S2_taxonomies($mapping_type: String = "", $client_name: String = "") {
    s2_taxonomies(
      where: { _eq: { mapping_type: $mapping_type, client_name: $client_name } }
    ) {
      taxonomy_string
      taxonomy_name
      taxonomy_id
      taxonomy_description
      taxonomy_prefix
      mapping_type
      last_updated_ts
      last_updated_by
      created_ts
      created_by
      client_name
      visible
    }
  }
`;

//REWORK
export const GET_S2_TAXONOMIES = gql`
  query S2_taxonomies($mapping_type: String = "") {
    s2_taxonomies(where: { _eq: { mapping_type: $mapping_type } }) {
      taxonomy_string
      taxonomy_name
      taxonomy_id
      taxonomy_description
      taxonomy_prefix
      mapping_type
      last_updated_ts
      last_updated_by
      created_ts
      created_by
      client_name
      tax_author
      visible
    }
  }
`;

export const GET_S2_TAXONOMY_INDEX_HIGHEST = gql`
  query S2_taxonomies {
    s2_taxonomies(orderBy: { taxonomy_id: desc }, limit: 1) {
      taxonomy_id
    }
  }
`;

export const GET_S2_TAXONOMY_BY_PREFIX = gql`
  query S2_taxonomies(
    $mapping_type: String = ""
    $taxonomy_prefix: String = ""
  ) {
    s2_taxonomies(
      where: {
        _eq: { mapping_type: $mapping_type, taxonomy_prefix: $taxonomy_prefix }
      }
    ) {
      taxonomy_string
      taxonomy_name
      taxonomy_id
      taxonomy_description
      taxonomy_prefix
      mapping_type
      last_updated_ts
      last_updated_by
      created_ts
      created_by
      client_name
      visible
      tax_author
    }
  }
`;

export const INSERT_S2_TAXONOMY = gql`
  mutation S2_insert_new_taxonomy($object: S2TaxonomyObj!) {
    s2_insert_new_taxonomy(object: $object) {
      taxonomy_string
      taxonomy_name
      taxonomy_id
      taxonomy_description
      taxonomy_prefix
      mapping_type
      last_updated_ts
      last_updated_by
      created_ts
      created_by
      client_name
      tax_author
      visible
    }
  }
`;

export const UPDATE_S2_TAXONOMY = gql`
  mutation S2_update_taxonomy(
    $object: S2TaxonomyObj
    $where: S2TaxonomyWhereInput
  ) {
    s2_update_taxonomy(object: $object, where: $where) {
      taxonomy_id
      taxonomy_name
      taxonomy_description
      taxonomy_string
      taxonomy_prefix
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      client_name
      tax_author
      visible
    }
  }
`;

//
//
//
//
// TAXONOMY SEQUENCES
//
//
//
//
//
export const GET_S2_DATADQ_SEQUENCES_STRING = 'GET_S2_DATADQ_SEQUENCES';
export const GET_S2_DATADQ_SEQUENCES_BY_PREFIX_STRING =
  'GET_S2_DATADQ_SEQUENCES_BY_PREFIX';
export const GET_S2_HIGHEST_DATADQ_SEQUENCE_ID_STRING =
  'GET_S2_HIGHEST_DATADQ_SEQUENCE_ID';
export const GET_S2_GLOBAL_DATADQ_SEQUENCES_STRING =
  'GET_S2_GLOBAL_DATADQ_SEQUENCES';
export const GET_S2_CLIENT_DATADQ_SEQUENCES_STRING =
  'GET_S2_CLIENT_DATADQ_SEQUENCES';
//REWORK
export const GET_S2_DATADQ_SEQUENCES = gql`
  query S2_datadq_sequence(
    $mapping_type: String = ""
    $client_name: String = ""
  ) {
    s2_datadq_sequence(
      where: { _eq: { mapping_type: $mapping_type, client_name: $client_name } }
    ) {
      sequence_id
      sequence_name
      sequence_prefix
      sequence_suffix
      sequence_numeric
      sequence_increment
      sequence_num_of_digits
      mapping_type
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      client_name
      visible
      tax_author
    }
  }
`;

export const GET_S2_HIGHEST_DATADQ_SEQUENCE_ID = gql`
  query S2_datadq_sequence {
    s2_datadq_sequence(orderBy: { sequence_id: desc }, limit: 1) {
      sequence_id
    }
  }
`;

export const GET_S2_DATADQ_SEQUENCES_BY_PREFIX = gql`
  query S2_datadq_sequence(
    $prefix: String = ""
    $mapping_type: String = ""
    $client_name: String = ""
  ) {
    s2_datadq_sequence(
      where: {
        _eq: {
          mapping_type: $mapping_type
          sequence_prefix: $prefix
          client_name: $client_name
        }
      }
    ) {
      sequence_id
      sequence_name
      sequence_prefix
      sequence_suffix
      sequence_numeric
      sequence_increment
      sequence_num_of_digits
      mapping_type
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      client_name
      visible
      tax_author
    }
  }
`;

export const INSERT_NEW_DATADQ_SEQUENCE = gql`
  mutation S2_insert_data_sequence($object: S2DatadqSequenceObj) {
    s2_insert_new_datadq_sequence(object: $object) {
      sequence_id
      sequence_name
      sequence_prefix
      sequence_suffix
      sequence_numeric
      sequence_increment
      sequence_num_of_digits
      mapping_type
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      client_name
      tax_author
    }
  }
`;

export const UPDATE_S2_DATADQ_SEQUENCE = gql`
  mutation S2_update_datadq_sequence(
    $object: S2DatadqSequenceObj
    $where: S2DatadqSequenceWhereInput
  ) {
    s2_update_data_sequence(object: $object, where: $where) {
      sequence_name
      sequence_id
      sequence_prefix
      sequence_suffix
      sequence_numeric
      sequence_increment
      sequence_num_of_digits
      mapping_type
      created_ts
      created_by
      last_updated_by
      last_updated_ts
      client_name
      tax_author
    }
  }
`;

//obsolete but used - remove later
export const INSERT_NEW_MAPPING_CREATIVE = gql`
  mutation S2_insert_new_mapping($object: S2MappingObj!) {
    s2_insert_new_mapping(object: $object) {
      customer_name
      campaign_name
      user_column_13
      user_column_1
      user_column_2
      user_column_3
      user_column_4
      user_column_5
      user_column_6
      user_column_7
      user_column_8
      user_column_9
      user_column_10
      user_column_11
      user_column_12
      verified
      compliant
      created_by
      created_ts
      last_updated_by
      last_updated_ts
      mapping_type
    }
  }
`;

//obsolete but used - remove later
export const INSERT_NEW_MAPPING_PLACEMENT = gql`
  mutation S2_insert_new_mapping($object: S2MappingObj!) {
    s2_insert_new_mapping(object: $object) {
      customer_name
      user_column_1
      campaign_name
      user_column_2
      user_column_3
      user_column_4
      user_column_5
      user_column_6
      user_column_7
      user_column_8
      user_column_9
      country
      user_column_10
      source_system
      user_column_11
      user_column_12
      user_column_13
      user_column_14
      user_column_15
      user_column_16
      user_column_17
      user_column_18
      user_column_19
      user_column_20
      user_column_21
      user_column_22
      user_column_23
      user_column_24
      user_column_25
      user_column_26
      user_column_27
      user_column_28
      verified
      compliant
      created_by
      created_ts
      last_updated_by
      last_updated_ts
      mapping_type
    }
  }
`;

//remove later - switch any instances with Update and set list item
export const INSERT_NEW_LIST_ITEM = gql`
  mutation Insert_new_lists($objects: [S2ListObj!]!) {
    s2_insert_new_lists(objects: $objects) {
      list_id
      list_name
      list_value
      list_alt_value
      sequence_no
      user_defined_columns
      created_by
      created_ts
      last_updated_by
      last_updated_ts
      mapping_type
    }
  }
`;

export const UPDATE_LIST_ITEM = gql`
  mutation S2_update_list($update: S2ListObj, $where: S2ListsWhereInput) {
    s2_update_list(update: $update, where: $where)
  }
`;

export const SET_LIST = gql`
  mutation Set_lists($list: [S2ListObj!]!) {
    set_lists(list: $list) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      mapping_id
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name
      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_RELATIONSHIPS_GLOBAL_STRING = 'GET_RELATIONSHIPS_GLOBAL';
export const GET_RELATIONSHIPS_STRING = 'GET_RELATIONSHIPS';

//client_name
export const GET_RELATIONSHIPS = gql`
  query Get_relationships($tableType: String, $where: S2ListsWhereInput) {
    get_relationships(
      table_type: $tableType
      where: $where
      orderBy: { sequence_no: asc }
    ) {
      client_name
      column_name
      column_type
      default_value
      display_name
      user_defined_name
      is_editable
      is_mandatory
      is_visible
      can_override_list
      list_name
      mapping_type
      table_id
      row_id
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      sequence_no
      visible
      no_blanks
      max_char_length
      distinct
      is_number
      exceptions
    }
  }
`;

export const SET_RELATIONSHIPS = gql`
  mutation Set_relationships($relationship: [S2RelationshipObj!]!) {
    set_relationships(relationship: $relationship) {
      client_name
      column_name
      column_type
      default_value
      display_name
      user_defined_name
      is_editable
      is_mandatory
      is_visible
      can_override_list
      list_name
      mapping_type
      table_id
      row_id
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      sequence_no
      visible
      no_blanks
      max_char_length
      distinct
      is_number
      exceptions
    }
  }
`;

export const SET_MAPPINGS = gql`
  mutation Set_mappings($array: [S2Mapping2Obj]) {
    set_mappings_2(array: $array) {
      mapping_id
    }
  }
`;

export const GET_HISTORY = gql`
  query Get_history($rowId: Int) {
    get_history(row_id: $rowId) {
      audit_ts
      row_id
      audit_user
      audit_count
    }
  }
`;

export const GET_HISTORY_DETAIL = gql`
  query Get_history_detail($rowId: Int) {
    get_history_detail(row_id: $rowId) {
      audit_ts
      audit_user
      column_name
      cell_value
      prev_cell_value
    }
  }
`;

export const REFRESH_CHANGES = gql`
  query Refresh_Changes {
    refresh_changes
  }
`;

export const GET_COLUMN_WIDTH = gql`
  query Get_column_width(
    $mappingType: String
    $clientName: String
    $tableType: String
  ) {
    get_column_width(
      mapping_type: $mappingType
      client_name: $clientName
      table_type: $tableType
    ) {
      column
      width
      cell_id
    }
  }
`;

export const SET_COLUMN_WIDTH = gql`
  mutation Set_column_width(
    $mappingType: String
    $tableType: String
    $clientName: String
    $columnWidths: [InDataDQColumn]
  ) {
    set_column_width(
      mapping_type: $mappingType
      table_type: $tableType
      client_name: $clientName
      column_widths: $columnWidths
    ) {
      column
      width
      cell_id
    }
  }
`;
